import React from 'react';
import { Avatar, Button, Divider, List, Skeleton } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { DeleteOutlined } from '@ant-design/icons';

export default function ButtonsRegionList({ buttons, removeButton, title }) {

    const handleRemoveBtn = (id) => () => {
        removeButton(id);
    }
    return (
        <div
            id="scrollableDiv"
            style={{
                height: 280,
                width: 200,
                overflow: 'auto',
                padding: '0 16px',
                border: '1px solid rgba(140, 140, 140, 0.35)',
            }}
        >
            <h4 style={{ margin: "0 auto", textAlign: "center" }}>{title}</h4>
            <InfiniteScroll
                dataLength={buttons}
                loader={
                    <Skeleton
                        avatar
                        paragraph={{
                            rows: 1,
                        }}
                        active
                    />
                }
                endMessage={<Divider plain />}
                scrollableTarget="scrollableDiv"
            >
                <List
                    dataSource={buttons}
                    renderItem={(btn) => (
                        <List.Item key={btn.id}>
                            <List.Item.Meta
                                title={btn.title}
                            />
                            <Button
                                type="primary"
                                danger
                                icon={<DeleteOutlined />}
                                onClick={handleRemoveBtn(btn.id)}
                            />


                        </List.Item>
                    )}
                />
            </InfiniteScroll>
        </div>
    )
}
