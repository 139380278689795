import styled from '@emotion/styled';
import Title from 'antd/es/typography/Title';
import React, { useCallback, useState } from 'react';
import { setIsEditorOpen, setSelected } from '../../features/regionsSlice';
import { useDispatch } from 'react-redux';


const Wrapper = styled.div`
	position: absolute;
    color: #fff;
    border: 1px solid #000890;
    padding: 5px;
    text-align: center;
    background-color: rgba(0,0,0,0.5);
    cursor: pointer;
    
`;

const ITEM_TITLE_STYLES = {
    color: "#fff",
    backgroundColor: "rgba(0,0,0,0.8)",
    textAlign: "center",
    position: 'absolute',
    padding: "0 5px",
    top: '-15px',
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "max-content",
    fontFamily: "IBMPlexSansHebrew-Regular"
}

export default function RegionItem({ region }) {
    const dispatch = useDispatch();
    const [isHover, setIsHover] = useState(false);

    const selectRegion = useCallback((region) => {
        dispatch(setSelected(region))
    }, [dispatch])

    const openEditor = useCallback(() => {
        selectRegion(region);
        dispatch(setIsEditorOpen(true))
    }, [dispatch, selectRegion, region]);

    return (
        <div onClick={openEditor}>
            <Wrapper
                onMouseEnter={() => setIsHover(true)}
                onMouseLeave={() => setIsHover(false)}
                style={{
                    ...region.position,
                    opacity: region.active ? '1' : '0.5',
                    zIndex: isHover ? 999 : ''
                }}
            >
                {isHover && <Title style={{ ...ITEM_TITLE_STYLES }}
                    level={5}>
                    {region.name}
                </Title>}
                {/* {isHover && <Title style={{ ...ITEM_TITLE_STYLES, top: '', bottom: '-20px', transform: "translate(-50%, 50%)" }}
                    level={5}>
                    {region.nameEng}
                </Title>} */}
            </Wrapper >

        </div>
    )
}
