import axios from 'axios';

let unregisterResponse;

function handleResponseHeaders({ redirectto }, navigate) {
  if (redirectto && navigate) {
    navigate(redirectto, { state: { redirectto }, replace: false });
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  initInterceptors(navigate) {
    unregisterResponse = axios.interceptors.response.use(
      (response) => {
        handleResponseHeaders(response.headers, navigate);
        return response;
      },
      (err) => {
        handleResponseHeaders(err.response.headers);
        return Promise.reject(err);
      }
    );
  },
  clearInterceptors() {
    axios.interceptors.response.eject(unregisterResponse);
  }
};
