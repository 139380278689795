import styled from '@emotion/styled';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Button, Modal, Spin } from 'antd';

import Title from 'antd/es/typography/Title';
import { deleteFile } from '../../../features/api/filesAPI';
import LoaderContainer from '../../styledComponents/LoaderContainer';
import FileUploader from '../../FileUploader/FileUploader';
import PreviewModal from '../PreviewModal/PreviewModal';
import GalleryItem from './GalleryItem';

const COLORS = [
    "Green", // A strong, vibrant green
    "LimeGreen", // Vivid, bright green
    "DarkOrange", // Rich, vibrant orange
    "DeepPink", // Bright, saturated pink
    "DodgerBlue", // Vivid, sky-blue
    "Tomato", // Bright red with a hint of orange
    "Chartreuse", // Vivid, bright green-yellow
    "MediumSpringGreen", // Bright, almost neon green
    "OrangeRed", // Deep, vibrant orange-red
    "HotPink", // Bright, eye-catching pink
    "Crimson", // Deep, vibrant red
    "DarkViolet", // Deep, vibrant purple
    "RoyalBlue", // Bright, deep blue
    "SeaGreen", // Strong green with a touch of blue
    "Turquoise", // Bright, medium turquoise
];


const FilesWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  width: 100%;
  flex-wrap: wrap;
  overflow-y: auto;
  min-height: 525px;
  max-height: 600px;
  flex-wrap: wrap;
`;

const HeaderControlsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
`;

export default function RegionGalleryModal({
    isVisible,
    status,
    handleConfirm,
    handleClose,
    handleAfterClose,
    items
}) {

    const [localFiles, setLocalFiles] = useState();
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewFile, setPreviewFile] = useState();
    const [previewModalKey, setPreviewModalKey] = useState('');
    const [isDeleting, setIsDeleting] = useState(false);

    const [fileInfoActive, setFileInfoActive] = useState();

    const { selectedGallery } = useSelector(
        (state) => state.regions
    );

    const adjustFilesForBooks = useCallback((files) => {
        if (selectedGallery === "documents") {
            const books = [];
            files.forEach((file, idx) => {
                const split = file.fileName.split("-");
                const exists = books.find((book) => book.book === split[0])
                if (split[0] && !exists) {
                    books.push({ book: split[0], border: COLORS[idx] });
                }
            });

            files = files.map((file) => {
                const split = file.fileName.split("-");
                const border = books.find((item) => item.book === split[0]).border;
                return {
                    ...file,
                    border

                }
            });
            return files;
        }
        return files;
    }, [selectedGallery]);


    const setGalleryFiles = (files) => {
        handleConfirm(selectedGallery, files);
        setLocalFiles([]);
        handleClose();
    }

    const deleteItem = (item) => async () => {
        setIsDeleting(true);
        await deleteFile(item.id);
        setIsDeleting(false);
        const toUpdate = localFiles.filter((file) => file.id !== item.id);
        setLocalFiles(toUpdate);
    };

    const assignToFilesList = (files) => {
        if (files.length && localFiles.length) {
            const ids = localFiles.map((file) => file.id);
            const toUpdate = files.filter((file) => !ids.includes(file.id));
            setLocalFiles([...localFiles, ...toUpdate]);

        } else if (files.length) {
            setLocalFiles(files);
        }
    };

    const handleCancelPreview = () => setPreviewVisible(false);

    const openPreview = (file) => () => {
        if (file.type === 'application') {
            window.open(file.url, "_blank");
            return;
        }
        setPreviewModalKey(`key-${Math.random() * 1000}`);
        setPreviewFile(file);
        setPreviewVisible(true);
    };

    useEffect(() => {
        if (items) {
            setLocalFiles(adjustFilesForBooks(items));
        }
    }, [adjustFilesForBooks, items]);

    return (
        <>
            <Modal
                keyboard
                open={isVisible}
                onOk={handleConfirm}
                onCancel={handleClose}
                okText={'Save'}
                cancelText={'Cancel'}
                confirmLoading={status === 'loading'}
                okButtonProps={{ type: 'primary' }}
                afterClose={() => setGalleryFiles(localFiles)}
                width={'80%'}
                footer={[
                    <Button
                        key="submit"
                        type="primary"
                        onClick={() => setGalleryFiles(localFiles)}
                    >
                        Set
                    </ Button>

                ]}
            >
                <HeaderControlsWrapper>
                    <Title style={{ margin: 0 }} level={4}>{`Manage Gallery - ${selectedGallery}`}</Title>
                </HeaderControlsWrapper>
                {selectedGallery
                    ? (
                        <>
                            <FileUploader
                                buttonText="Add Files"
                                updateFiles={assignToFilesList}
                                fileTypes=".jpg,.png,.mp4"
                                multiple
                                alignToStart
                            />
                            <FilesWrapper>
                                {localFiles?.map((item, idx) => (
                                    <GalleryItem
                                        key={item.id + idx}
                                        item={item}
                                        openPreview={openPreview(item)}
                                        deleteItem={deleteItem(item)}
                                        isDeleting={isDeleting}
                                        maxLength={localFiles.length}
                                        fileInfoActive={fileInfoActive}
                                        setFileInfoActive={setFileInfoActive}
                                    />
                                ))}
                            </FilesWrapper>
                            <PreviewModal
                                modalKey={previewModalKey}
                                isOpen={previewVisible}
                                file={previewFile}
                                handleCancel={handleCancelPreview}
                            />
                        </>
                    ) : (
                        <LoaderContainer minHeight={'300px'}>
                            <Spin size="large" />
                        </LoaderContainer>
                    )}
            </Modal>
        </>
    )
}