import styled from "@emotion/styled";

import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import RegionEditorModal from "../components/Modals/RegionEditorModal/RegionEditorModal";
import RegionItem from "../components/MapEditor/RegionItem";
import { getAllRegions, setIsEditorOpen } from "../features/regionsSlice";
import MapBaseImg from '../images/map_base.png';
import { Button } from "antd";
import BioManagerModal from "../components/Modals/RegionEditorModal/BioManager/BioManagerModal";
import LinksEditorModal from "../components/Modals/RegionEditorModal/LinksManager/LinksEditorModal";


const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	margin: 0 auto;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
`;

const MapWrapper = styled.div`
	width: 50%;
	margin: 0 auto;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
`;

const MapBase = styled.img`
	width: 100%;
`;

const ButtonsManagementWrapper = styled.div`
	height: 50px;
	position: absolute;
	top: 35px;
	left: 55px;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	flex-flow: column;
	gap:15px;
`;

const ManageButtonsBtn = styled(Button)`
	/* position: absolute;
	top: 35px;
	left: 55px; */
`;

const REGIONS_POS = [
	{
		id: 1,
		name: "איזור1",
		nameEng: "region1",
		active: true,
		position: {
			width: "16%",
			height: "9%",
			top: "2%",
			left: '42%',
			transform: "rotate(0deg)"
		}
	},
	{
		id: 2,
		name: "איזור2",
		nameEng: "region2",
		active: true,
		position: {
			width: "2%",
			height: "3.5%",
			top: "8%",
			left: '64.5%',
			transform: "rotate(0deg)"
		}
	},
	{
		id: 3,
		name: "איזור3",
		nameEng: "region3",
		active: true,
		position: {

			width: "6%",
			height: "21%",
			top: "13%",
			left: '24%',
			transform: "rotate(3.5deg)"
		}
	},
	{
		id: 4,
		name: "איזור4",
		nameEng: "region4",
		active: true,
		position: {
			width: "5%",
			height: "10%",
			top: "13%",
			left: '31%',
			transform: "rotate(0deg)"
		}
	},
	{
		id: 5,
		name: "איזור5",
		nameEng: "region5",
		active: true,
		position: {
			width: "6%",
			height: "10%",
			top: "13%",
			left: '37%',
			transform: "rotate(0deg)"
		}
	},
	{
		id: 6,
		name: "איזור6",
		nameEng: "region6",
		active: true,
		position: {
			width: "12%",
			height: "11.5%",
			top: "11.5%",
			left: '44%',
			transform: "rotate(0deg)"
		}
	},
	{
		id: 7,
		name: "איזור7",
		nameEng: "region7",
		active: true,
		position: {
			width: "6%",
			height: "10.5%",
			top: "12.5%",
			left: '57%',
			transform: "rotate(0deg)"
		}
	},
	{
		id: 8,
		name: "איזור8",
		nameEng: "region8",
		active: true,
		position: {
			width: "5.5%",
			height: "21%",
			top: "12.5%",
			left: '69.5%',
			transform: "rotate(-3deg)"
		}
	},
	{
		id: 9,
		name: "איזור9",
		nameEng: "region9",
		active: true,
		position: {
			width: "4.5%",
			height: "7%",
			top: "26.5%",
			left: '43.5%',
			transform: "rotate(0deg)"
		}
	},
	{
		id: 10,
		name: "איזור10",
		nameEng: "region10",
		active: true,
		position: {
			width: "2.5%",
			height: "7%",
			top: "26.5%",
			left: '48.5%',
			transform: "rotate(0deg)"
		}
	},
	{
		id: 11,
		name: "איזור11",
		nameEng: "region11",
		active: true,
		position: {
			width: "5%",
			height: "4%",
			top: "30%",
			left: '52%',
			transform: "rotate(0deg)"
		}
	},
	{
		id: 12,
		name: "איזור12",
		nameEng: "region12",
		active: true,
		position: {
			width: "6%",
			height: "2.5%",
			top: "24.5%",
			left: '57%',
			transform: "rotate(0deg)"
		}
	},
	{
		id: 13,
		name: "איזור13",
		nameEng: "region13",
		active: true,
		position: {
			width: "2%",
			height: "4.5%",
			top: "27%",
			left: '61.5%',
			transform: "rotate(-2deg)"
		}
	},
	{
		id: 14,
		name: "איזור14",
		nameEng: "region14",
		active: true,
		position: {
			width: "11%",
			height: "17%",
			top: "34%",
			left: '6%',
			transform: "rotate(2deg)"
		}
	},
	{
		id: 15,
		name: "איזור15",
		nameEng: "region15",
		active: true,
		position: {
			width: "6%",
			height: "15%",
			top: "35.5%",
			left: '19%',
			transform: "rotate(2deg)"
		}
	},
	{
		id: 16,
		name: "איזור16",
		nameEng: "region16",
		active: true,
		position: {
			width: "2.7%",
			height: "3%",
			top: "47%",
			left: '29%',
			transform: "rotate(0deg)"
		}
	},
	{
		id: 17,
		name: "איזור17",
		nameEng: "region17",
		active: true,
		position: {
			width: "4.5%",
			height: "3%",
			top: "36.5%",
			left: '37%',
			transform: "rotate(0deg)"
		}
	},
	{
		id: 18,
		name: "איזור18",
		nameEng: "region18",
		active: true,
		position: {
			width: "3%",
			height: "3%",
			top: "47.2%",
			left: '39%',
			transform: "rotate(0deg)"
		}
	},
	{
		id: 19,
		name: "איזור19",
		nameEng: "region19",
		active: true,
		position: {
			width: "1.5%",
			height: "6%",
			top: "39.7%",
			left: '40.8%',
			transform: "rotate(0deg)"
		}
	},
	{
		id: 20,
		name: "איזור20",
		nameEng: "region20",
		active: true,
		position: {
			width: "1.5%",
			height: "1.5%",
			top: "45.5%",
			left: '41%',
			transform: "rotate(0deg)"
		}
	},
	{
		id: 21,
		name: "איזור21",
		nameEng: "region21",
		active: true,
		position: {
			width: "14%",
			height: "17%",
			top: "34.5%",
			left: '43%',
			transform: "rotate(0deg)"
		}
	},
	{
		id: 22,
		name: "איזור22",
		nameEng: "region22",
		active: true,
		position: {
			width: "2.7%",
			height: "3.6%",
			top: "35.5%",
			left: '57.3%',
			transform: "rotate(0deg)"
		}
	},
	{
		id: 23,
		name: "איזור23",
		nameEng: "region23",
		active: true,
		position: {
			width: "2%",
			height: "3%",
			top: "38.7%",
			left: '57.3%',
			transform: "rotate(0deg)"
		}
	},
	{
		id: 24,
		name: "איזור24",
		nameEng: "region24",
		active: true,
		position: {
			width: "2%",
			height: "2.7%",
			top: "41.4%",
			left: '57.3%',
			transform: "rotate(0deg)"
		}
	},
	{
		id: 25,
		name: "איזור25",
		nameEng: "region25",
		active: true,
		position: {
			width: "2%",
			height: "6%",
			top: "44.1%",
			left: '57.3%',
			transform: "rotate(0deg)"
		}
	},
	{
		id: 26,
		name: "איזור26",
		nameEng: "region26",
		active: true,
		position: {
			width: "8%",
			height: "14%",
			top: "36%",
			left: '74%',
			transform: "rotate(-1deg)"
		}
	},
	{
		id: 27,
		name: "איזור27",
		nameEng: "region27",
		active: true,
		position: {
			width: "8%",
			height: "13%",
			top: "36%",
			left: '85%',
			transform: "rotate(0deg)"
		}
	},
	{
		id: 28,
		name: "איזור28",
		nameEng: "region28",
		active: true,
		position: {
			width: "2%",
			height: "3%",
			top: "60%",
			left: '37.5%',
			transform: "rotate(0deg)"
		}
	},
	{
		id: 29,
		name: "איזור29",
		nameEng: "region29",
		active: true,
		position: {
			width: "2.7%",
			height: "3%",
			top: "51.5%",
			left: '45%',
			transform: "rotate(0deg)"
		}
	},
	{
		id: 30,
		name: "איזור30",
		nameEng: "region30",
		active: true,
		position: {
			width: "6%",
			height: "11%",
			top: "51.7%",
			left: '50.5%',
			transform: "rotate(0deg)"
		}
	},
	{
		id: 31,
		name: "איזור31",
		nameEng: "region31",
		active: true,
		position: {
			width: "12.5%",
			height: "11%",
			top: "51.7%",
			left: '65%',
			transform: "rotate(0deg)"
		}
	},
	{
		id: 32,
		name: "איזור32",
		nameEng: "region32",
		active: true,
		position: {
			width: "3%",
			height: "5%",
			top: "57%",
			left: '90%',
			transform: "rotate(0deg)"
		}
	},
	{
		id: 33,
		name: "איזור33",
		nameEng: "region33",
		active: true,
		position: {
			width: "4.5%",
			height: "3.5%",
			top: "66%",
			left: '28.7%',
			transform: "rotate(0deg)"
		}
	},
	{
		id: 34,
		name: "איזור34",
		nameEng: "region34",
		active: true,
		position: {
			width: "1%",
			height: "5%",
			top: "68.3%",
			left: '45%',
			transform: "rotate(0deg)"
		}
	},
	{
		id: 35,
		name: "איזור35",
		nameEng: "region35",
		active: true,
		position: {
			width: "3%",
			height: "5.5%",
			top: "64.5%",
			left: '68%',
			transform: "rotate(0deg)"
		}
	},
	{
		id: 36,
		name: "איזור36",
		nameEng: "region36",
		active: true,
		position: {
			width: "5%",
			height: "7.4%",
			top: "64.8%",
			left: '71.5%',
			transform: "rotate(0deg)"
		}
	},
	{
		id: 37,
		name: "איזור37",
		nameEng: "region37",
		active: true,
		position: {
			width: "13%",
			height: "14%",
			top: "70%",
			left: '16.5%',
			transform: "rotate(0deg)"
		}
	},
	{
		id: 38,
		name: "איזור38",
		nameEng: "region38",
		active: true,
		position: {
			width: "13%",
			height: "8%",
			top: "73%",
			left: '70.8%',
			transform: "rotate(-38deg)"
		}
	},
	{
		id: 39,
		name: "איזור39",
		nameEng: "region39",
		active: true,
		position: {
			width: "3.5%",
			height: "5.5%",
			top: "69%",
			left: '91%',
			transform: "rotate(0deg)"
		}
	},
	{
		id: 40,
		name: "איזור40",
		nameEng: "region40",
		active: true,
		position: {
			width: "16%",
			height: "14%",
			top: "84%",
			left: '42%',
			transform: "rotate(0deg)"
		}
	},
	{
		id: 41,
		name: "איזור41",
		nameEng: "region41",
		active: true,
		position: {
			width: "2.5%",
			height: "6%",
			top: "88%",
			left: '66%',
			transform: "rotate(0deg)"
		}
	},
	{
		id: 42,
		name: "איזור42",
		nameEng: "region42",
		active: true,
		position: {
			width: "12%",
			height: "18%",
			top: "79%",
			left: '78%',
			transform: "rotate(0deg)"
		}
	},
	{
		id: 43,
		name: "איזור43",
		nameEng: "region43",
		active: true,
		position: {
			width: "2.5%",
			height: "3.5%",
			top: "74%",
			left: '37.5%',
			transform: "rotate(0deg)"
		}
	},
	{
		id: 44,
		name: "איזור44",
		nameEng: "region44",
		active: true,
		position: {
			width: "5%",
			height: "5%",
			top: "93%",
			left: '59.5%',
			transform: "rotate(0deg)"
		}
	}
];


export default function MapEditor() {
	const dispatch = useDispatch();

	const { items: REGIONS, isEditorOpen, loadRegionStatus } = useSelector(state => state.regions);

	const [isBioManager, setIsBioManager] = useState(false);
	const [isLinksManager, setIsLinksManager] = useState(false);

	const openBioManager = () => setIsBioManager(true);
	const closeBioManager = () => setIsBioManager(false);


	const openLinksManager = () => setIsLinksManager(true);
	const closeLinksManager = () => setIsLinksManager(false);

	const loadRegions = useCallback(() => {
		dispatch(getAllRegions())
	}, [dispatch])


	const closeEditor = useCallback(() => {
		dispatch(setIsEditorOpen(false))
	}, [dispatch])

	useEffect(() => {
		loadRegions();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])


	return (
		<>
			<Wrapper>
				<ButtonsManagementWrapper>
					<ManageButtonsBtn onClick={openBioManager}>Manage Biographies</ManageButtonsBtn>
					<ManageButtonsBtn onClick={openLinksManager}>Manage Links</ManageButtonsBtn>
				</ButtonsManagementWrapper>
				<MapWrapper>
					<MapBase src={MapBaseImg} />
					{REGIONS.map((region) => (
						<RegionItem
							key={region.id}
							region={region}
						/>
					))}
				</MapWrapper>
			</Wrapper>
			<RegionEditorModal
				isVisible={isEditorOpen}
				handleClose={closeEditor}
				status={loadRegionStatus}
			/>
			<LinksEditorModal
				isVisible={isLinksManager}
				handleClose={closeLinksManager}
			/>
			<BioManagerModal
				isVisible={isBioManager}
				handleClose={closeBioManager}
			/>
		</>
	);
}
