import axios from 'axios';

//const baseURL = process.env.NODE_ENV === 'development' ? '' : '';

export function fetchAllBios() {
  return axios.get('/bio/getAll');
}
export function fetchBioById(id) {
  return axios.get('/bio/', {
    params: {
      id
    }
  });
}
export function createBioBtn(btnData) {
  return axios.post(`/bio/create`, { bio: btnData });
}
export function saveBioBtn(btnData) {
  return axios.post(`/bio/update`, { bio: btnData });
}

export function deleteBioBtn(id) {
  return axios.post(`/bio/delete`, { id });
}
