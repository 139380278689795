import axios from 'axios';

//const baseURL = process.env.NODE_ENV === 'development' ? '' : '';

export function fetchInterfaces() {
  return axios.get('/regions/get');
}
export function fetchRegionFiles(files) {
  return axios.get('/regions/getGallery', {
    params: {
      files: files.map((f) => f.id)
    }
  });
}

export function getRegionById(id) {
  return axios.get(`/regions/get?id=${id}`);
}
// export function createInterface(interfaceData) {
//   return axios.post(`/interfaces/new`, interfaceData);
// }
export function saveRegion(interfaceData) {
  return axios.post(`/regions/update`, interfaceData);
}
