export const REGIONS_POS = [
    {
        id: 1,
        position: {
            width: "14.8%",
            height: "10%",
            top: "1.5%",
            left: '42.6%',
            transform: "rotate(0deg)"
        }
    },
    {
        id: 2,
        position: {
            width: "2%",
            height: "3.5%",
            top: "7%",
            left: '63.7%',
            transform: "rotate(0deg)"
        }
    },
    {
        id: 3,
        position: {
            width: "8.8%",
            height: "22.8%",
            top: "12%",
            left: '22.5%',
            transform: "rotate(0deg)"
        }
    },
    {
        id: 4,
        position: {
            width: "5.5%",
            height: "10.2%",
            top: "12.5%",
            left: '30.6%',
            transform: "rotate(0deg)"
        }
    },
    {
        id: 5,
        position: {
            width: "6.3%",
            height: "9.8%",
            top: "12.8%",
            left: '36.4%',
            transform: "rotate(0deg)"
        }
    },
    {
        id: 6,
        position: {
            width: "12.1%",
            height: "10.5%",
            top: "12%",
            left: '43.8%',
            transform: "rotate(0deg)"
        }
    },
    {
        id: 7,
        position: {
            width: "7%",
            height: "11.2%",
            top: "11%",
            left: '56.67%',
            transform: "rotate(0deg)"
        }
    },
    {
        id: 8,
        position: {
            width: "7.5%",
            height: "25.7%",
            top: "7.5%",
            left: '68.5%',
            transform: "rotate(0deg)"
        }
    },
    {
        id: 9,
        position: {
            width: "5%",
            height: "7%",
            top: "26.5%",
            left: '42.6%',
            transform: "rotate(0deg)"
        }
    },
    {
        id: 10,
        position: {
            width: "3.5%",
            height: "7.2%",
            top: "25.7%",
            left: '47.6%',
            transform: "rotate(0deg)"
        }
    },
    {
        id: 11,
        position: {
            width: "4.6%",
            height: "4%",
            top: "29.3%",
            left: '51.7%',
            transform: "rotate(0deg)"
        }
    },
    {
        id: 12,
        position: {
            width: "6.7%",
            height: "4%",
            top: "23.2%",
            left: '57.3%',
            transform: "rotate(0deg)"
        }
    },
    {
        id: 13,
        position: {
            width: "2%",
            height: "5.5%",
            top: "26%",
            left: '61%',
            transform: "rotate(0deg)"
        }
    },
    {
        id: 14,
        position: {
            width: "11%",
            height: "16.8%",
            top: "33.8%",
            left: '8.3%',
            transform: "rotate(0deg)"
        }
    },
    {
        id: 15,
        position: {
            width: "5.4%",
            height: "14.5%",
            top: "34.5%",
            left: '18.8%',
            transform: "rotate(0deg)"
        }
    },
    {
        id: 16,
        position: {
            width: "2.9%",
            height: "3.5%",
            top: "46%",
            left: '29%',
            transform: "rotate(0deg)"
        }
    },
    {
        id: 17,
        position: {
            width: "4.5%",
            height: "3.4%",
            top: "35.5%",
            left: '37.2%',
            transform: "rotate(0deg)"
        }
    },
    {
        id: 18,
        position: {
            zIndex: 99,
            width: "3.8%",
            height: "2.4%",
            top: "47%",
            left: '38.8%',
            transform: "rotate(0deg)",
        }
    },
    {
        id: 19,
        position: {
            width: "1.45%",
            height: "6.8%",
            top: "38.5%",
            left: '40.9%',
            transform: "rotate(0deg)"
        }
    },
    {
        id: 20,
        position: {
            width: "1.8%",
            height: "2.8%",
            top: "44.8%",
            left: '40.8%',
            transform: "rotate(0deg)"
        }
    },
    {
        id: 21,
        position: {
            width: "14.8%",
            height: "16.6%",
            top: "34.3%",
            left: '42.2%',
            transform: "rotate(0deg)"
        }
    },
    {
        id: 22,
        position: {
            width: "3.5%",
            height: "4.7%",
            top: "34.5%",
            left: '56.7%',
            transform: "rotate(0deg)"
        }
    },
    {
        id: 23,
        position: {
            width: "3%",
            height: "4%",
            top: "38.6%",
            left: '56%',
            transform: "rotate(0deg)"
        }
    },
    {
        id: 24,
        position: {
            width: "2.9%",
            height: "3.5%",
            top: "41.5%",
            left: '56.1%',
            transform: "rotate(0deg)"
        }
    },
    {
        id: 25,
        position: {
            width: "3.1%",
            height: "5.5%",
            top: "43.5%",
            left: '56.1%',
            transform: "rotate(0deg)"
        }
    },
    {
        id: 26,
        position: {
            width: "8.5%",
            height: "14.4%",
            top: "35.5%",
            left: '73.5%',
            transform: "rotate(0deg)"
        }
    },
    {
        id: 27,
        position: {
            width: "8.5%",
            height: "14%",
            top: "35%",
            left: '83%',
            transform: "rotate(0deg)"
        }
    },
    {
        id: 28,
        position: {
            width: "3.2%",
            height: "3.2%",
            top: "59.2%",
            left: '36.4%',
            transform: "rotate(0deg)"
        }
    },
    {
        id: 29,
        position: {
            width: "2.7%",
            height: "4%",
            top: "49.7%",
            left: '45.1%',
            transform: "rotate(0deg)"
        }
    },
    {
        id: 30,
        position: {
            width: "6.2%",
            height: "11.5%",
            top: "51%",
            left: '50.3%',
            transform: "rotate(0deg)"
        }
    },
    {
        id: 31,
        position: {
            width: "12.5%",
            height: "11.5%",
            top: "51%",
            left: '64.8%',
            transform: "rotate(0deg)"
        }
    },
    {
        id: 32,//here
        position: {
            width: "4%",
            height: "7%",
            top: "53.9%",
            left: '89%',
            transform: "rotate(0deg)"
        }
    },
    {
        id: 33,
        position: {
            width: "6.8%",
            height: "6.2%",
            top: "63.9%",
            left: '27.5%',
            transform: "rotate(0deg)"
        }
    },
    {
        id: 34,
        position: {
            width: "1.5%",
            height: "6%",
            top: "66%",
            left: '45.5%',
            transform: "rotate(0deg)"
        }
    },
    {
        id: 35,
        position: {
            width: "3%",
            height: "6.7%",
            top: "62.7%",
            left: '68.2%',
            transform: "rotate(0deg)"
        }
    },
    {
        id: 36,
        position: {
            width: "4.7%",
            height: "7.3%",
            top: "62.5%",
            left: '71.5%',
            transform: "rotate(0deg)"
        }
    },
    {
        id: 37,
        position: {
            width: "12.5%",
            height: "15.7%",
            top: "69.2%",
            left: '15%',
            transform: "rotate(0deg)"
        }
    },
    {
        id: 38,
        position: {
            width: "12.5%",
            height: "15%",
            top: "70.2%",
            left: '72.1%',
            transform: "rotate(-5deg)"
        }
    },
    {
        id: 39,
        position: {
            width: "4.5%",
            height: "7%",
            top: "64%",
            left: '90.1%',
            transform: "rotate(0deg)"
        }
    },
    {
        id: 40,
        position: {
            width: "15.5%",
            height: "14%",
            top: "80%",
            left: '41.8%',
            transform: "rotate(0deg)"
        }
    },
    {
        id: 41,
        position: {
            width: "3%",
            height: "10.2%",
            top: "83.7%",
            left: '66.3%',
            transform: "rotate(0deg)"
        }
    },
    {
        id: 42,
        position: {
            width: "12%",
            height: "18%",
            top: "76.2%",
            left: '78%',
            transform: "rotate(0deg)"
        }
    },
    {
        id: 43,
        position: {
            width: "3.2%",
            height: "3.2%",
            top: "73.5%",
            left: '36.5%',
            transform: "rotate(0deg)"
        }
    },
    {
        id: 44,
        name: "איזור44",
        nameEng: "region44",
        active: true,
        position: {
            width: "3.5%",
            height: "5%",
            top: "93.5%",
            left: '60.5%',
            transform: "rotate(0deg)"
        }
    }
];
