import React, { useCallback, useEffect, useRef, useState } from 'react';

import styled from '@emotion/styled';
import TextArea from 'antd/es/input/TextArea';
import Title from 'antd/es/typography/Title';

import { COLORS } from '../../../config/colors';

import { css } from '@emotion/react';
import { useSelector } from 'react-redux';
import TextImageDecoration from '../../../images/bottom_info_text.png';
import CoverImageBG from '../../../images/image_info_base.png';
import TextInfoBG from '../../../images/image_text_box.png';

import { isMobile } from '../../../utils/general';
import ArrowBtn from '../../styledComponents/ArrowBtn';
import PreviewModal from '../PreviewModal/PreviewModal';
import PreviewBtn from '../../styledComponents/PreviewBtn';
import { PlayCircleOutlined } from '@ant-design/icons';


const Wrapper = styled.div`
    width:100vw;
    height: 100%;
    z-index: 999;
    position: relative;
`;


const NamesSectionWrapper = styled.div`
    width: 100%;
    height: 160px;
    margin-top: 9px;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: flex-start;

    .regionName{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        gap: 10px;
    }
    .fileName{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }
`;

const MainContentSectionWrapper = styled.div`
    width: 100%;
    height: 100%;
    
    white-space: pre-wrap;
    transform: scale(0.9);

    .innerGrid{
        width: 100%;
        height: ${props => props.isPdf ? "75vh" : "610px"};
        display: grid;
        grid-template-columns: ${props => props.isPdf ? "50%" : "48% 52%"};
        gap: 35px;
        margin: 0 auto;
        transform: ${props => props.isPdf ? "" : (props.lang === "en" ? "translateX(-1.5%)" : "translateX(1.2%)")};
    }
`;

const GalleryTitleSectionWrapper = styled.div`
    width: 100%;
    white-space: pre-wrap;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const BoxBaseImg = styled.img`
    position: absolute;
    left:0;
    right:0;
    bottom:0;
    top: 0;
`

const BoxBaseCoverImg = styled.img`
    position: absolute;
    height: 100%;

    transform:  ${props => props.isPdf ?
        `translate(${props.lang === 'en' ? '50%' : '-50%'}, 0)` : ""};

    ${props => !props.isPdf && css`
        left:0;
        right:0;
        bottom:0;
        top: 0;
    `}
`

const BoxCoverImg = styled.div`
    position: absolute;
    height: ${props => props.height};
    width: 90%;
    background-image: ${props => props.url && `url("${props.url}")`};
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
`

const TextAreaCustom = styled(TextArea)`
    white-space: pre-wrap;
    resize: none !important;
    border: none;
    font-family: IBMPlexSansHebrew-Medium;
    background: transparent;
    font-size: 1.8rem;
    width: 550px;
    max-height: 480px;
    padding-bottom: 30px;
    position: absolute;
    top: 35px;  

    ${props => props.lang === 'he' ? css`
        right: 35px;
    ` : css`
        left: 35px;
    `}

    :hover, :focus {
        background-color: transparent;
        border: none;
    }
`



const ArrowsWrapper = styled.div`
    width: 100%;//${props => props.isMobile ? "100%" : "85%"};
    height: 10px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: -50px;
`;

const CenterContent = styled.div`
	width: 1280px;
	height: 720px;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
`;

const DotsContainer = styled.div`
	width: 100%;
	height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap:15px;
	/* position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%); */
`;

const IdxDot = styled.div`
    width: 25px;
    height: 25px;
    border: 2px solid var(--bg-color);
    border-radius: 50%;
    background-color: var(--fg-color);

    transition: background-color 0.3s ease-in ;

    ${props => props.selected && css`
        background-color: var(--bg-color);
    `}
`;

const PlayBtn = styled.button`
  border: none;
  margin: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0,0,0,.2);
  cursor: pointer;
`;

export default function ImageInfoPage({ allItems, regionItems, region }) {

    const [items, setItems] = useState([]);
    const [bookItems, setBookItems] = useState([]);

    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewFile, setPreviewFile] = useState();
    const [previewModalKey, setPreviewModalKey] = useState('');
    const [selectedImage, setSelectedImage] = useState();

    const [isPortrait, setIsPortrait] = useState(false);
    const [isSquare, setIsSquare] = useState(false);

    const [isPlaying, setIsPlaying] = useState(false);


    const [itemIdx, setItemIdx] = useState(0);
    const [pageNum, setPageNum] = useState(1);

    const [pdfIdx, setPdfIdx] = useState(0);
    const [pagePdfNum, setPagePdfNum] = useState(1);

    const imageLoaded = useRef(false);
    const isBookOpen = useRef(false);
    const videoPlayerRef = useRef();

    const { isRegionGalleryPdf, selectedGallery } = useSelector(
        (state) => state.regions
    );

    const { language } = useSelector(
        (state) => state.language
    );

    const handleCancelPreview = () => {
        setPreviewVisible(false);
        isBookOpen.current = false;
    }

    const openPreview = (file) => () => {
        setPreviewModalKey(`key-${Math.random() * 1000}`);
        setPreviewFile(file);
        setPreviewVisible(true);
        setTimeout(() => {
            isBookOpen.current = true;
        }, 50);
    };

    const isHebrew = () => language === "he";
    const isEnglish = () => language === "en";

    const nextItem = useCallback(() => {
        if (isRegionGalleryPdf && isBookOpen.current) {
            let nextIdx = pdfIdx + 1;
            if (nextIdx >= bookItems.length) {
                nextIdx = 0;
            }
            setPdfIdx(nextIdx);
            return;
        }
        let nextIdx = itemIdx + 1;
        if (nextIdx >= items.length) {
            nextIdx = 0;
        }
        setItemIdx(nextIdx);
    }, [isRegionGalleryPdf, itemIdx, items.length, pdfIdx, bookItems.length]);

    const prevItem = useCallback(() => {
        if (isRegionGalleryPdf && isBookOpen.current) {
            let nextIdx = pdfIdx - 1;
            if (nextIdx < bookItems.length) {
                nextIdx = bookItems.length - 1;
            }
            setPdfIdx(nextIdx);
            return;
        }
        let nextIdx = itemIdx - 1;
        if (nextIdx < 0) {
            nextIdx = items.length - 1;
        }
        setItemIdx(nextIdx);
    }, [isRegionGalleryPdf, itemIdx, pdfIdx, bookItems.length, items.length])

    const checkPortrait = useCallback(() => {
        if (selectedImage) {
            const img = new Image();
            img.onload = function () {
                // console.log(`portrait: ${this.width > this.height}, width: ${this.width}, height: ${this.height}`);
                setIsPortrait(this.width < this.height);
            }
            img.src = selectedImage.type === "video" ? selectedImage.videoThumbnail : selectedImage.url;
        }
    }, [selectedImage])

    const checkSquare = useCallback(() => {
        if (selectedImage) {
            const img = new Image();
            img.onload = function () {
                // console.log(`square: ${this.width === this.height}, width: ${this.width}, height: ${this.height}`);
                setIsSquare(this.width === this.height);
            }
            img.src = selectedImage.type === "video" ? selectedImage.videoThumbnail : selectedImage.url;
        }
        return false;
    }, [selectedImage]);

    const getGalleryHebHeader = () => {
        switch (selectedGallery) {
            case "photos":
                return "תצלומים";
            case "objects":
                return "חפצים";
            case "testimonies":
                return "קטעי עדויות";
            case "art":
                return "אומנות"
            case "documents":
                return "מסמכים"
            default:
                return null;
        }
    }

    const playVideo = () => {
        if (videoPlayerRef.current) {
            videoPlayerRef.current.play();
        }
    };

    useEffect(() => {
        checkPortrait();
        checkSquare();
    }, [checkPortrait, checkSquare, selectedImage]);

    useEffect(() => {
        if (!isBookOpen.current && itemIdx !== undefined && itemIdx !== null) {
            const image = isRegionGalleryPdf && previewVisible ? bookItems[pdfIdx] : items[itemIdx];

            setSelectedImage(image);
            setPageNum(itemIdx + 1);
            setPreviewFile(isRegionGalleryPdf && previewVisible ? bookItems[pdfIdx] : items[itemIdx]);
            setPdfIdx(0);
        } else {
            setSelectedImage(bookItems[pdfIdx]);
            setPagePdfNum(pdfIdx + 1);
            setPreviewFile(bookItems[pdfIdx]);
        }
    }, [items, itemIdx, setSelectedImage, isRegionGalleryPdf, bookItems, previewVisible, pdfIdx, isBookOpen]);


    useEffect(() => {
        if (!isBookOpen.current && isRegionGalleryPdf && previewVisible && selectedImage?.book) {
            let bookItems = regionItems.filter((img) => {
                const split = img.fileName.split("-");
                return split[0] === selectedImage.book;
            });
            bookItems = bookItems.sort((imgA, imgB) => {
                const splitA = imgA.fileName.split("-");
                const splitB = imgB.fileName.split("-");
                return Number(splitA[1]) > Number(splitB[1]) ? -1 : 1;
            });
            let bookName, bookNameEng;
            bookItems = bookItems.map((img, idx) => {

                if (idx === 0) {
                    bookName = img.title;
                    bookNameEng = img.titleEng;
                }
                return { ...img, title: bookName, titleEng: bookNameEng };
            });
            setSelectedImage(bookItems[0]);
            setBookItems(bookItems);
            isBookOpen.current = true;
        }
    }, [isRegionGalleryPdf, previewVisible, regionItems, selectedImage, isBookOpen]);

    useEffect(() => {
        if (!previewVisible || !isRegionGalleryPdf) {
            isBookOpen.current = false;
            setItems(allItems);

            if (!selectedImage) {
                setSelectedImage(allItems[0]);
            }
        }

    }, [allItems, isRegionGalleryPdf, selectedImage, previewVisible, isBookOpen]);

    return selectedImage && (
        <Wrapper>
            <CenterContent>
                <NamesSectionWrapper>
                    <div className='regionName'>
                        {isHebrew() && <Title
                            level={2}
                            style={{
                                fontFamily: "IBMPlexSansHebrew-Bold",
                                color: COLORS.btnBg,
                                margin: 0,
                                fontSize: '4rem'
                            }}
                        >
                            {region?.name}
                        </Title>}
                        {isEnglish() && <Title
                            level={2}
                            style={{
                                fontFamily: "IBMPlexSansHebrew-Bold",
                                color: COLORS.btnBg,
                                margin: 0,
                                fontSize: '4rem'
                            }}
                        >
                            {region?.nameEng}
                        </Title>}
                    </div>
                    <div className='fileName'>


                        {isHebrew() &&
                            <Title
                                level={2}
                                style={{
                                    fontFamily: "IBMPlexSansHebrew-Bold",
                                    color: COLORS.btnBg,
                                    margin: 0,
                                    fontSize: '2.5rem'
                                }}
                            >
                                {`${selectedImage.title ? `${selectedImage.title}` : ""}`}

                            </Title>}
                        {isEnglish() &&
                            <Title
                                level={2}
                                style={{
                                    fontFamily: "IBMPlexSansHebrew-Bold",
                                    color: COLORS.btnBg,
                                    margin: 0,
                                    fontSize: '2.5rem',
                                }}
                            >
                                {`${selectedImage.titleEng ? `${selectedImage.titleEng}` : ""}`}
                            </Title>}

                    </div>
                    {/* <Title
                        level={3}
                        style={{
                            fontFamily: "IBMPlexSansHebrew-Bold",
                            color: COLORS.btnBg,
                            margin: 0,
                            textAlign: "center"
                        }}
                    >
                        {`${pageNum}/${items?.length}`}
                    </Title> */}
                </NamesSectionWrapper>

                <MainContentSectionWrapper
                    isMobile={isMobile()}
                    // isPdf={isRegionGalleryPdf}
                    lang={language}
                >
                    <div className="innerGrid" >
                        <div style={{
                            position: 'relative',
                            width: "100%",
                            height: "90%",
                            top: 10,
                            // gridColumn: language === "he" ? 1 : 2
                        }}>
                            <BoxBaseImg
                                src={TextInfoBG}
                                alt="text base"
                                width="100%"
                                height="100%"
                            />

                            {isHebrew() &&
                                <TextAreaCustom
                                    lang={language}
                                    dir='rtl'
                                    value={selectedImage.info}
                                    rows={20}
                                    cols={60}
                                    placeholder="לא נמצא ערך"
                                />}
                            {isEnglish() &&
                                <TextAreaCustom
                                    lang={language}
                                    value={selectedImage.infoEng}
                                    rows={20}
                                    cols={60}
                                    placeholder="No info found"
                                />}

                            <img
                                src={TextImageDecoration}
                                alt="bottom decoration"
                                style={{
                                    position: 'absolute',
                                    width: '92.7%',
                                    bottom: '30px',
                                    right: '20px',
                                    left: '20px',
                                }}
                            />
                        </div>
                        <div style={{
                            position: 'relative',
                            width: "100%",
                            height: "90%",
                            top: 10,
                            // gridColumn: language === "he" ? 2 : 1
                        }}>
                            <BoxBaseCoverImg
                                // isPdf={isRegionGalleryPdf}
                                src={CoverImageBG}
                                alt="image info base"
                                // lang={language}
                                width="100%"
                                height={"90%"}
                            // style={{ top: isRegionGalleryPdf ? '10%' : 0, }}
                            />
                            {selectedImage.type === "video" ? (
                                <div>
                                    <video
                                        ref={videoPlayerRef}
                                        onPlay={() => setIsPlaying(true)}
                                        onPause={() => setIsPlaying(false)}
                                        src={selectedImage?.url}
                                        style={isHebrew() ? {
                                            width: "92%",
                                            height: "90%",
                                            position: "absolute",
                                            top: '50%',
                                            left: '50%',
                                            transform: "translate(-50%, -50%)"
                                        } : {
                                            width: "92%",
                                            height: "90%",
                                            position: "absolute",
                                            top: '50%',
                                            right: '50%',
                                            transform: "translate(50%, -50%)"
                                        }}
                                        controls
                                        autoPlay
                                        muted
                                    />
                                    {!isPlaying && <PlayBtn onClick={playVideo}>
                                        <PlayCircleOutlined style={{ color: "#fff", fontSize: "10rem" }} />
                                    </PlayBtn>
                                    }
                                </div>) :
                                (<BoxCoverImg
                                    id={selectedImage.type === "video" ? "carousel-image-vid" : "carousel-image"}
                                    url={encodeURI(selectedImage.type === "video" ? selectedImage.videoThumbnail : selectedImage.url)}
                                    height={isRegionGalleryPdf || isPortrait ? "90%" : "80%"}
                                    style={isHebrew() ? {
                                        top: '50%',
                                        left: '50%',
                                        transform: "translate(-50%, -50%)"
                                    } : {
                                        top: isRegionGalleryPdf ? '50%' : '50%',
                                        right: '50%',
                                        transform: "translate(50%, -50%)"
                                    }}
                                />)}
                            {selectedImage.type !== "video" && <PreviewBtn
                                onClick={openPreview(selectedImage)}
                                style={{
                                    bottom: "15px",
                                    right: !isHebrew() ? "2.5%" : "",
                                    left: !isEnglish() ? "2.5%" : ""
                                }}
                            />}

                        </div>
                    </div>
                    {/* {items.length > 1 && <DotsContainer>
                        {items.map((_, idx) => (
                            <IdxDot selected={idx === itemIdx} />
                        ))}
                    </DotsContainer>} */}
                    {/* <GalleryTitleSectionWrapper>
                        {isHebrew() && <Title
                            level={2}
                            style={{
                                fontFamily: "IBMPlexSansHebrew-Bold",
                                color: COLORS.btnBg,
                                margin: 0,
                                fontSize: '2.5rem'
                            }}
                        >
                            {`${getGalleryHebHeader()} `}
                        </Title>}
                        {isEnglish() && <Title
                            level={2}
                            style={{
                                fontFamily: "IBMPlexSansHebrew-Bold",
                                color: COLORS.btnBg,
                                margin: 0,
                                fontSize: '2.5rem',
                                textTransform: "uppercase"
                            }}
                        >
                            {`${selectedGallery} `}
                        </Title>}
                    </GalleryTitleSectionWrapper> */}
                </MainContentSectionWrapper>

                {!previewVisible &&
                    (allItems.length > 1)
                    && <ArrowsWrapper isMobile={isMobile()}>
                        <ArrowBtn
                            handle={"previous"}
                            style={{
                                bottom: "0",
                                left: "40px",
                                transform: "translate(0, 50%)"
                            }}
                            onClick={prevItem}
                        />
                        <ArrowBtn
                            handle={"next"}
                            style={{
                                bottom: "0",
                                right: "40px",
                                transform: "translate(0, 50%) scale(-1)"
                            }}
                            onClick={nextItem}
                        />
                    </ArrowsWrapper>}
            </CenterContent>

            <PreviewModal
                modalKey={previewModalKey}
                isOpen={previewVisible && imageLoaded}
                file={previewFile}
                handleCancel={handleCancelPreview}
                prevItem={prevItem}
                nextItem={nextItem}
                totalPages={isRegionGalleryPdf ? bookItems.length : items?.length}
                pageNum={isRegionGalleryPdf ? pagePdfNum : pageNum}
                isPortrait={isPortrait}
                isSquare={isSquare}
                width={isMobile() ? (isRegionGalleryPdf ? '100%' : '80%') : (isRegionGalleryPdf ? '100%' : '80%')}
            />

        </Wrapper>
    )
}
