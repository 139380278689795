import axios from 'axios';

export function updateFile(file) {
  return axios.post(`/files/update`, { file });
}

export function deleteFile(id) {
  return axios.delete(`/files/delete?id=${id}&force=true`);
}

export function checkFileExists(name) {
  return axios.get(`/files/checkexists?name=${name}`);
}
