import styled from '@emotion/styled';

export default styled.div`
  width: 100%;
  height: 100%;
  min-height: ${(props) => props.minHeight || ''};
  display: flex;
  align-items: center;
  justify-content: center;
`;
