import { Button, Card, Popover } from 'antd'
import React, { useState } from 'react'
import BioItemInfoPopover from './BioItemInfoPopover'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import PromptDialog from '../../../PromptDialog/PromptDialog';

export default function BioItemCard({ bio, language, deleteItem, updateItem }) {

    const [isEditBio, setIsEditBio] = useState(false);
    const [isDeletePrompt, setIsDeletePrompt] = useState(false);

    const openEditBio = () => {
        setIsEditBio(true);
    }

    const closeEditBio = () => {
        setIsEditBio(false);
    }

    const openDeletePrompt = () => {
        setIsDeletePrompt(true);
    }
    const closeDeletePrompt = () => {
        setIsDeletePrompt(false);
    }

    const deleteMe = () => {
        deleteItem(bio.id);
    }

    return (
        <>
            <Card

                title={language === "he" ? bio.title : bio.titleEng}
                type="inner"
                style={{ minWidth: 300 }}
                extra={
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "15px"
                    }}>
                        <Popover
                            key={bio.id}
                            open={isEditBio}
                            content={<BioItemInfoPopover
                                bioItem={bio}
                                closeMe={closeEditBio}
                                updateItem={updateItem}
                            />
                            }
                            title="Bio Info"
                            trigger="click"
                        >
                            <Button
                                onClick={openEditBio}
                                type='primary'
                                icon={<EditOutlined />}
                            >
                                Edit
                            </Button>
                        </Popover>
                        <Button
                            onClick={openDeletePrompt}
                            shape="circle"
                            danger
                            icon={<DeleteOutlined />}
                        />
                    </div>
                }
            >
                {language === "he" ? bio.info : bio.infoEng}
            </Card>
            <PromptDialog
                isOpen={isDeletePrompt}
                title={`Delete ${bio.title}`}
                content={"Delete bio?"}
                handleClose={closeDeletePrompt}
                handleConfirm={deleteMe}
                confirmType="error"
                confirmText="Delete"
                cancelText="Cancel"
            />
        </>
    )
}
