import styled from '@emotion/styled';
import React from 'react';
import { useSelector } from 'react-redux';
import { COLORS } from '../../config/colors';


const Btn = styled.button`
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    color:  #09090b;
    background-color: ${COLORS.btnBg};
    position: absolute;
    top: 30px;
    right: 50px;
    border: none;
    border-radius: 10px;
    text-transform: uppercase;
    font-family: IBMPlexSansHebrew-Bold;
    font-size: 2.2rem;
    box-shadow: 0px 0px 49px 1px rgba(0,0,0,0.3);
    z-index: 99;
    cursor: pointer;
    transition: transform .2s ease-in-out;

    :hover{
        transform: scale(1.02);
        filter: grayscale(50%);
    }
    :active{
        color:  ${COLORS.btnBg};
        background-color: ${COLORS.colorBrown};
    }
   
`;

export default function LanguageBtn({ ...props }) {
    const { language } = useSelector(
        (state) => state.language
    );

    return (
        <Btn {...props}>
            {language === "he" ? 'en' : "he"}
        </Btn>
    )
}
