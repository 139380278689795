import styled from "@emotion/styled";
import { useCallback, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { LogoutOutlined } from "@ant-design/icons";
import { Button, Layout } from "antd";
import PromptDialog from "../PromptDialog/PromptDialog";

import Title from "antd/es/typography/Title";
import { logout } from "../../features/authSlice";

const Wrapper = styled.div`
	width: 100%;
	height: 65px;
	padding: 10px;
	display: flex;
	text-align: center;
	align-items: center;
	justify-content: flex-start;
	z-index: 9999;
`;

const NavRight = styled.div`
	display: flex;
	flex-wrap: nowrap;
	width: 50%;
	height: 100%;
	gap: 15px;
	align-items: center;
	justify-content: flex-start;

	h4 {
		color: #fff;
		margin: 0;
	}
`;

const NavLeft = styled.div`
	display: flex;
	flex-wrap: nowrap;
	width: 100%;
	height: 100%;
	gap: 15px;
	align-items: center;
	justify-content: flex-end;
`;

const EditorControls = styled.div`
	/* width: 90%; */
	height: 45px;
	display: inline-flex;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: flex-start;
`;

const SaveButton = styled(Button)`
	margin-right: 15px;
	border: 1px solid green !important;
	color: #000 !important;
	background-color: #90de90 !important;

	&:hover {
		background-color: green !important;
		color: #ffffff !important;
	}
`;

const { Header } = Layout;

export default function HeaderControl() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();

	const backClick = useRef();
	const [isSavePrompt, setIsSavePrompt] = useState(false);

	const currentRoute = location.pathname;

	const { logoutStatus, isLoggedIn } = useSelector((state) => state.auth);


	const confirmLeaveEditorPrompt = () => {
		backClick.current = true;
		setIsSavePrompt(true);
	};
	const closePrompt = () => {
		backClick.current = false;
		setIsSavePrompt(false);
	};

	const closeAndGoBack = useCallback(() => {
		closePrompt();
		navigate("/", { state: { from: location }, replace: true });
	}, [location, navigate]);

	const userLogout = useCallback(() => dispatch(logout()), [dispatch]);

	return currentRoute !== '/map' && (
		<>
			<Header>
				<Wrapper>
					<NavRight>
						{currentRoute === "/" && (
							<>
								<Title level={4}>Map Editor</Title>

								{/* <EditorControls>
									<SaveButton
										type="default"
										size="large"
										loading={false}
										icon={<SaveOutlined />}
										onClick={() => { }}
									>
										Save
									</SaveButton>
								</EditorControls> */}
							</>
						)}
					</NavRight>
					<NavLeft>
						{isLoggedIn && (
							<Button
								type="primary"
								danger
								onClick={userLogout}
								loading={logoutStatus === "loading"}
								icon={<LogoutOutlined />}
							>
								Logout
							</Button>
						)}
					</NavLeft>
				</Wrapper>
			</Header>

			<PromptDialog
				isOpen={isSavePrompt}
				title="שמירת ממשק רכב"
				content={"בטוח ששמרתם את הממשק רכב ?"}
				handleClose={closePrompt}
				handleConfirm={confirmLeaveEditorPrompt}
				confirmType="default"
				confirmText="לשמור ולחזור"
				cancelText="ביטול"
				// status={createStatus}
				footer={[
					<Button
						key="other"
						type="danger"
						style={{ float: "right" }}
						onClick={closeAndGoBack}
					>
						לממשקים בלי לשמור
					</Button>,
					<Button key="cancel" onClick={closePrompt}>
						ביטול
					</Button>,
					// <Button key="confirm" type="primary" onClick={saveInterface}>
					// 	לשמור ולממשקים
					// </Button>,
				]}
			/>
		</>
	);
}
