import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  language: "he",
};


export const languageSlice = createSlice({
  name: "language",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setLanguage: (state, action) => {
      state.language = action.payload;
    },



  },

  extraReducers: (builder) => {
    // builder
    //   .addCase(getAllRegions.pending, (state) => {
    //     state.loadAllRegionsStatus = "loading";
    //   })
    //   .addCase(getAllRegions.rejected, (state) => {
    //     state.loadAllRegionsStatus = "idle";
    //   })
    //   .addCase(getAllRegions.fulfilled, (state, action) => {
    //     state.loadAllRegionsStatus = "idle";
    //     state.items = action.payload;
    //   })




  }
});

export const {
  setLanguage,

} = languageSlice.actions;

export default languageSlice.reducer;
