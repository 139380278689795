import styled from '@emotion/styled';
import React from 'react';
import { useSelector } from 'react-redux';


import FullScreen from '../../icons/fullscreen-icon.svg';
import BookIcon from '../../icons/read-book-icon.svg';


const Btn = styled.button`
    width: 45px;
    height: 45px;
    margin: 10px 0 12px 8px;
    background-color: transparent;
    border: none;
    position: absolute;
    cursor: pointer;
 
    transition: transform .2s ease-in-out filter .2s ease-in-out;

    :hover{
        transform: scale(1.1);
        filter: sepia(80%);
    }
    :active{
        filter: sepia(80%);
    }
`

export default function PreviewBtn({ ...props }) {
    const { isRegionGalleryPdf } = useSelector(
        (state) => state.regions
    );
    //<ReadOutlined style={{ fontSize: "3rem", color: "#fff" }} />
    return (
        <Btn {...props} >
            {isRegionGalleryPdf ?
                <img src={BookIcon} alt="read-book-icon" /> :
                <img src={FullScreen} alt="full0screen-icon" />
            }
        </Btn>

    )
}
