import styled from '@emotion/styled';
import Icon from '@ant-design/icons/lib/components/Icon';

import { COLORS } from '../../config/colors';

const Btn = styled.button`
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${COLORS.btnBg};
    border: none;
    border-radius: 10px;
    cursor: pointer;
    position: absolute;
    /* top: 30px; */
    /* left: 50px; */
    z-index: 999;
    transition: transform .2s ease-in-out;

    :hover{
        transform: scale(1.02);
        filter: grayscale(50%);
    }
    :active{
        fill:  ${COLORS.btnBg} !important;
        background-color: ${COLORS.colorBrown};
    }
`;

const IconSVG = () => (<svg height="70px" width="100px" id="Capa_1"
    viewBox="0 0 26.676 26.676" >
    <g>
        <path d="M26.105,21.891c-0.229,0-0.439-0.131-0.529-0.346l0,0c-0.066-0.156-1.716-3.857-7.885-4.59
   c-1.285-0.156-2.824-0.236-4.693-0.25v4.613c0,0.213-0.115,0.406-0.304,0.508c-0.188,0.098-0.413,0.084-0.588-0.033L0.254,13.815
   C0.094,13.708,0,13.528,0,13.339c0-0.191,0.094-0.365,0.254-0.477l11.857-7.979c0.175-0.121,0.398-0.129,0.588-0.029
   c0.19,0.102,0.303,0.295,0.303,0.502v4.293c2.578,0.336,13.674,2.33,13.674,11.674c0,0.271-0.191,0.508-0.459,0.562
   C26.18,21.891,26.141,21.891,26.105,21.891z"/>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
    </g>
</svg>);


export default function BackBtn({ ...props }) {
    return (
        <Btn {...props}>
            <Icon component={IconSVG} style={{ width: '70%', height: '70%' }} />
        </Btn>
    )
}
