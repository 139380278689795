import styled from "@emotion/styled";
import { useState } from "react";

import { Button, Form, Input } from "antd";

import useAuth from "../hooks/useAuth";
import Title from "antd/es/typography/Title";

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-flow: column;
	width: 100%;
	height: 100%;
`;

export default function Login() {
	const { login, loginStatus } = useAuth();
	const [userData, setUserData] = useState({
		name: "",
		password: "",
	});


	const userLogin = () => {
		login(userData.name, userData.password);
	};

	const onFieldChange = (e) => {
		const { name, value } = e.target;
		let currentData = { ...userData };
		currentData[name] = value;
		setUserData(currentData);
	};

	return (
		<>
			<Wrapper>
				<Title style={{ textTransform: "uppercase" }} level={1} type="secondary">Login</Title>
				<Form
					name="login"
					size="large"
					labelCol={{
						span: 8,
					}}
					wrapperCol={{
						span: 16,
					}}
					initialValues={{
						remember: true,
					}}
					autoComplete="off"
				>
					<Form.Item
						label="User Name"
						name="name"
						rules={[
							{
								required: true,
								message: "Please enter user name",
							},
						]}
					>
						<Input onChange={onFieldChange} name="name" />
					</Form.Item>

					<Form.Item
						label="Password"
						name="password"
						rules={[
							{
								required: true,
								message: "Please enter password",
							},
						]}
					>
						<Input.Password onChange={onFieldChange} name="password" />
					</Form.Item>

					<Form.Item
						wrapperCol={{
							offset: 8,
							span: 16,
						}}
					>
						<Button
							type="primary"
							htmlType="submit"
							loading={loginStatus === "loading"}
							onClick={userLogin}
						>
							Login
						</Button>
					</Form.Item>
				</Form>
			</Wrapper>
		</>
	);
}
