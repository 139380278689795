import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Card, List, Popover } from 'antd';
import React, { useState } from 'react';
import PromptDialog from '../../../PromptDialog/PromptDialog';
import LinksItemInfoPopover from './LinksItemInfoPopover';

export default function LinksItemCard({ REGIONS, linkItem, language, deleteItem, updateItem }) {

    const [isEdit, setIsEdit] = useState(false);
    const [isDeletePrompt, setIsDeletePrompt] = useState(false);

    const openEdit = () => {
        setIsEdit(true);
    }

    const closeEdit = () => {
        setIsEdit(false);
    }

    const openDeletePrompt = () => {
        setIsDeletePrompt(true);
    }
    const closeDeletePrompt = () => {
        setIsDeletePrompt(false);
    }

    const deleteMe = () => {
        deleteItem(linkItem.id);
    }

    const isEnglish = () => language === "en";

    const getLinkParts = () => {
        return linkItem.link.split("|")
            .map((part, idx) => {
                const regionToSelect = REGIONS.find((region) => region.id === Number(part));
                let title = "";
                switch (idx) {
                    case 0:
                        title = isEnglish() ? "Region" : "איזור"
                        break;
                    case 1:
                        title = isEnglish() ? "Gallery" : "גלריה"
                        break;
                    default:
                        break;

                }
                return {
                    title,
                    value: idx === 0 ? (isEnglish() ? regionToSelect.nameEng : regionToSelect.name) : part
                }
            })
    }

    return (
        <>
            <Card
                title={language === "he" ? linkItem.title : linkItem.titleEng}
                type="inner"
                style={{ minWidth: 300 }}
                extra={
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "15px"
                    }}>
                        <Popover
                            open={isEdit}
                            content={<LinksItemInfoPopover
                                key={linkItem.id + Date.now()}
                                REGIONS={REGIONS}
                                linkItem={linkItem}
                                closeMe={closeEdit}
                                updateItem={updateItem}
                            />
                            }
                            title="Link Info"
                            trigger="click"
                        >
                            <Button
                                onClick={openEdit}
                                type='primary'
                                icon={<EditOutlined />}
                            >
                                Edit
                            </Button>
                        </Popover>

                        <Button
                            onClick={openDeletePrompt}
                            shape="circle"
                            danger
                            icon={<DeleteOutlined />}
                        />


                    </div>
                }
            >
                <List
                    itemLayout="vertical"
                    dataSource={getLinkParts()}
                    renderItem={(item) => (
                        <List.Item>
                            <List.Item.Meta
                                title={<a href="https://ant.design">{item.title}</a>}
                                description={item.value}
                            />
                        </List.Item>
                    )}
                />
            </Card>
            <PromptDialog
                isOpen={isDeletePrompt}
                title={`Delete ${linkItem.title}`}
                content={"Delete link?"}
                handleClose={closeDeletePrompt}
                handleConfirm={deleteMe}
                confirmType="error"
                confirmText="Delete"
                cancelText="Cancel"
            />
        </>
    )
}
