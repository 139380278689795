import styled from '@emotion/styled';
import React from 'react';

import ArrowIcon from '../../images/nav_arrow.png';



const Btn = styled.button`
    width: 80px;
    height: 80px;
    background-color: transparent;
    background-image: url(${ArrowIcon});
    background-size: cover;
    border:none;
    position: absolute;
    bottom: -35px;
    cursor: pointer;
 
    transition: transform .2s ease-in-out;

    :hover{
        transform: scale(1.02);
        filter: grayscale(50%);
    }
    :active{
        filter: brightness(70%);
    }
`

export default function ArrowBtn({ handle, ...props }) {

    return (
        <Btn {...props} />
    )
}
