import { GlobalOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { Button, Input } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import Title from 'antd/es/typography/Title';
import React, { useEffect, useState } from 'react';


const FileInfoWrapper = styled.div`
  width: 250px;
  display: flex;
  align-items: flex-start;
  flex-flow: column;
  justify-content: flex-start;

`;

const FileInfoWrapperBottom = styled.div`
  width: 250px;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;

`;
export default function BioItemInfoPopover({ bioItem, createItem, updateItem, closeMe }) {
    const [itemTitle, setItemTitle] = useState(bioItem?.title || "");
    const [itemTitleEng, setItemTitleEng] = useState(bioItem?.titleEng || "");
    const [editableParagraph, setEditableParagraph] = useState(bioItem?.info || "");
    const [editableParagraphEng, setEditableParagraphEng] = useState(bioItem?.infoEng || "");

    const [lang, setLang] = useState("he");

    const createBioInfo = async () => {
        const bioTpUpdate = {
            ...bioItem,
            title: itemTitle,
            titleEng: itemTitleEng,
            info: editableParagraph,
            infoEng: editableParagraphEng
        }
        if (createItem) {
            createItem(bioTpUpdate);
        }
    }

    const updateBioInfo = async () => {
        const bioTpUpdate = {
            ...bioItem,
            title: itemTitle,
            titleEng: itemTitleEng,
            info: editableParagraph,
            infoEng: editableParagraphEng
        }
        if (updateItem) {
            try {
                await updateItem(bioTpUpdate);
                closeMe();
            } catch (error) {

            }
        }

    }

    const toggleLanguage = () => {
        const langToChange = lang === "he" ? "en" : "he";
        setLang(langToChange);
    }

    const isHebrew = () => lang === "he";
    const isEnglish = () => lang === "en";

    const getDirection = () => {
        return lang === "he" ? "rtl" : "ltr";
    }
    const handleTitleChange = (e) => {
        const { value } = e.target;
        if (lang === "he") {
            setItemTitle(value);
            return;
        }
        setItemTitleEng(value);
    }

    const handleParagraphChange = (e) => {
        const { value } = e.target;
        if (lang === "he") {
            setEditableParagraph(value);
            return;
        }
        setEditableParagraphEng(value);
    }

    useEffect(() => {
        setItemTitle(bioItem?.title || "");
        setItemTitleEng(bioItem?.titleEng || "");
        setEditableParagraph(bioItem?.info || "");
        setEditableParagraphEng(bioItem?.infoEng || "");
    }, [bioItem]);

    return (
        <FileInfoWrapper dir={getDirection()} >
            <Button
                onClick={toggleLanguage}
                icon={<GlobalOutlined />}
                style={{ marginBottom: '15px' }}
            >
                {lang === "en" ? "he" : "en"}
            </Button>
            {isHebrew() && (
                <>
                    <Title level={5}>שם</Title>
                    <Input
                        placeholder="שם הישות"
                        value={itemTitle}
                        onChange={handleTitleChange}
                    />
                </>
            )}
            {isEnglish() && (
                <>
                    <Title level={5}>Name</Title>
                    <Input placeholder="Person Name"
                        value={itemTitleEng}
                        onChange={handleTitleChange}
                    />
                </>)}

            {isHebrew() && <>
                <Title level={5}>מידע</Title>
                <TextArea
                    style={{ whiteSpace: "pre-wrap", resize: 'none' }}
                    autoSize={false}
                    name="he"
                    value={editableParagraph}
                    onChange={handleParagraphChange}
                    rows={10}
                    cols={20}
                    placeholder="אנא הכניסו מידע על הישות הנבחרת"
                />
            </>}
            {isEnglish() && <>
                <Title level={5}>Info</Title>
                <TextArea
                    style={{ whiteSpace: "pre-wrap", resize: 'none' }}
                    autoSize={false}
                    name="he"
                    value={editableParagraphEng}
                    onChange={handleParagraphChange}
                    rows={10}
                    cols={20}
                    placeholder="Please enter the person's info"
                />
            </>}
            <FileInfoWrapperBottom>
                <Button
                    key="submit"
                    type="primary"
                    style={{ marginTop: '15px' }}
                    disabled={!itemTitle && !itemTitleEng}
                    onClick={!bioItem ? createBioInfo : updateBioInfo}>
                    {!bioItem ? "Create" : "Save"}
                </Button>
                <Button
                    key="close"
                    type="primary"
                    style={{ marginTop: '15px' }}
                    onClick={closeMe}
                    danger
                >
                    Cancel
                </Button>
            </FileInfoWrapperBottom>
        </FileInfoWrapper>
    )
}
