import axios from 'axios';

export function loginUser(data) {
  return axios.post('/register/login', data);
}
export function logoutUser() {
  return axios.get('/register/logout');
}

export function validateLogin() {
  return axios.get('/register/verifylogin');
}
