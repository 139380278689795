import styled from "@emotion/styled";
import axios from "axios";
import React, { useRef, useState } from "react";

import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Progress, Upload, message } from "antd";

import { checkFileExists } from "../../features/api/filesAPI";
import PromptDialog from "../PromptDialog/PromptDialog";

const Wrapper = styled.div`
	width: 100%;
	height: 80px;
	display: flex;
	flex-flow: column;
	align-items: ${(props) => (props.isStart ? "flex-start" : "center")};
	justify-content: center;
`;

const UploadButton = styled.div`
	border: 1px solid #000;
	text-align: center;
	padding: 15px;
	color: #000;
	cursor: pointer;
	transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;

	&:hover {
		background-color: rgba(0, 0, 0, 0.3);
		color: #fff;
	}
`;

let afterUploadCount, multiUploadFiles = [];

export default function FileUploader({
	buttonText,
	updateFile,
	updateFiles,
	isLoading,
	fileTypes = ".jpg,.png,.pdf,.mp4",
	boxButton = false,
	type = "text",
	multiple = false,
	alignToStart = false,
}) {
	const [uploadProgress, setUploadProgress] = useState(0);
	const [isOverwriteDialog, setIsOverwriteDialog] = useState(false);
	const [fileUploadStatus, setFileUploadStatus] = useState("");

	const fileUploaded = useRef();
	const multiFilesUploaded = useRef([]);

	const uploadButton = (
		<UploadButton>
			<PlusOutlined style={{ fontSize: "2rem" }} />
			<div style={{ marginTop: 8 }}>{buttonText}</div>
		</UploadButton>
	);

	const uploadProps = {
		name: "file",
		action: "/files/upload",
		multiple,
		accept: fileTypes,
		showUploadList: false,
		beforeUpload: async (file) => {
			multiUploadFiles = [];
			if (!multiple) {
				fileUploaded.current = file;
			} else {
				multiFilesUploaded.current.push(file);
			}

			afterUploadCount = multiFilesUploaded.current.length;
			const { data: exists } = await checkFileExists(file.name);
			setIsOverwriteDialog(exists);
			return !exists;
		},
		// data: {
		// 	index: multiple
		// 		? lastIndex.current + multiFilesUploaded.current.length
		// 		: selectedInterface?.galleryFiles.length,
		// },
		onChange(info) {
			if (info.file.status !== "uploading") {
				//console.log(info.file, info.fileList);
			}
			if (info.file.status === "done") {

				message.success(`File uploaded successfully`);
				if (updateFile) {
					updateFile(info.file.response[0]);
				}
				if (multiple && updateFiles) {
					multiUploadFiles.push(info.file.response[0]);
					if (afterUploadCount === multiUploadFiles.length) {
						updateFiles(multiUploadFiles);
					}
				}
			} else if (info.file.status === "error") {
				if (
					(Array.isArray(info.file?.response) &&
						info.file?.response?.includes("already exists")) ||
					info.file.error
				) {
					setIsOverwriteDialog(true);
					return;
				}
				message.error(`File upload failed, ${info.file.name}`);
			}
			setUploadProgress(Math.round(Number(info.file.percent)));
			setFileUploadStatus(info.file.status);
		},
	};

	const forceUpload = () => {
		const formData = new FormData();
		if (multiple) {
			multiFilesUploaded.current.forEach((file, index) => {
				formData.append("file", file, file.name);
			});
		} else {
			formData.append("file", fileUploaded.current);
			// formData.append("index", selectedInterface?.galleryFiles.length);
		}
		setFileUploadStatus("uploading");
		setUploadProgress(0);
		multiFilesUploaded.current = [];

		axios({
			url: "/files/upload?force=true",
			method: "POST",
			headers: {
				"Content-Type": "multipart/form-data",
			},
			data: formData,
			onUploadProgress: handleUploadProgress,
		})
			.then(({ data }) => {
				fileUploaded.current = null;
				multiFilesUploaded.current = [];
				if (updateFile) updateFile(data[0]);
				if (updateFiles) updateFiles(data);
				setFileUploadStatus("");
				message.success(
					data.length === 1 && data[0].fileName
						? `File uploaded, ${data[0].fileName}`
						: "Files uploaded"
				);
			})
			.catch((err) => {
				setFileUploadStatus("");
				message.error("Files upload failed");
			});
	};

	const closeOverwriteDialog = () => {
		setIsOverwriteDialog(false);
		setFileUploadStatus("");
	};

	const forceUploadDialog = () => {
		forceUpload();
		setIsOverwriteDialog(false);
	};

	const handleUploadProgress = (e) => {
		setUploadProgress(Number(Math.round((e.loaded * 100) / e.total)));
	};

	return (
		<>
			<Wrapper isStart={alignToStart}>
				<Upload {...uploadProps} listType={type}>
					{boxButton ? (
						uploadButton
					) : (
						<Button
							icon={<UploadOutlined />}
							loading={fileUploadStatus === "uploading" || isLoading}
						>
							{buttonText}
						</Button>
					)}
				</Upload>
				{fileUploadStatus === "uploading" && (
					<Progress
						style={{
							color: "#fff",
							width: "30%",
							transform: "translateX(-17px)",
						}}
						size="small"
						percent={uploadProgress}
					/>
				)}
			</Wrapper>
			<PromptDialog
				isOpen={isOverwriteDialog}
				title="One or More Files Already Exists"
				content={
					"Upload files any way?"
				}
				handleClose={closeOverwriteDialog}
				handleConfirm={forceUploadDialog}
				confirmType="default"
				confirmText="Overwrite"
				cancelText="Cancel"
			/>
		</>
	);
}
