import styled from "@emotion/styled";

import ClabsLogo from "../../images/clabs_logo.png";
import { Layout } from "antd";
import { useLocation } from "react-router-dom";


const Wrapper = styled.div`
	width: 100%;
	min-height: 41px;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap:15px;

	h5 {
		color: #fff;
		margin: 0 0 0 10px;
		font-size: 0.6rem;
	}

	img {
		cursor: pointer;
		image-rendering: -webkit-optimize-contrast;
	}
`;

const { Footer } = Layout;

export default function FooterControl() {
	const location = useLocation();

	const currentRoute = location.pathname;

	const openCreativeLabs = () => {
		window.open("http://www.clabs.co.il/", "_blank");
	};

	return currentRoute !== '/map' && (
		<Footer
			style={{
				position: 'fixed',
				bottom: 0,
				width: '100%',
				borderTop: '1px solid black',
				backgroundColor: '#001529'
			}}
		>
			<Wrapper>
				<h5>Created by</h5>
				<img
					src={ClabsLogo}
					alt="Creative Labs Logo"
					width="90"
					onClick={openCreativeLabs}
				/>
			</Wrapper>
		</Footer>
	);
}
