import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setIsRegionOpen, setSelected, setSelectedHighlight } from '../../features/regionsSlice';


const Wrapper = styled.div`
	position: absolute;
    color: #fff;
    text-align: center;
    cursor: pointer;
    filter: drop-shadow(-10px 0px 5px rgba(0,0,0,0.6));
    

    @keyframes highlight-init {
        from {
            filter: brightness(.5) sepia(50%) drop-shadow(-10px 0px 5px rgba(0,0,0,0.6));
        }

        to {
            filter: brightness(1) sepia(100%) drop-shadow(-10px 0px 5px rgba(0,0,0,0.6));
        }
    }

    @keyframes highlight-select {
        from {
            filter: brightness(.5) sepia(50%) drop-shadow(-10px 0px 5px rgba(0,0,0,0.6));
        }

        to {
            filter: brightness(1) sepia(100%) drop-shadow(-10px 0px 5px rgba(0,0,0,0.6));
        }
    }  

    :hover{
        filter: grayscale(50%) drop-shadow(-10px 0px 9px rgba(0,0,0,0.6));
    }
    :active{
        filter: grayscale(80%) drop-shadow(0px 0px 15px rgba(0,0,0,0.6));
    }

    ${props => props.firstAnimation && css`
        /* animation: highlight-init 0.5s 0.5s 3 alternate ease-in-out; */
    `};

    ${props => props.selected && css`
        animation: highlight-select 0.7s 0.5s 5 alternate ease-in-out;
    `};
    
    
`;


export default function RegionInteractiveItem({ region, isInMap }) {
    const dispatch = useDispatch();

    const regionRef = useRef();

    const [isInit, setIsInit] = useState(true);

    const { selectedHighlight } = useSelector(
        (state) => state.regions
    );

    const selectRegion = (region) => {
        dispatch(setSelected(region));
    }

    const openRegion = () => {
        selectRegion(region);
        dispatch(setIsRegionOpen(true))
    };

    const selectRegionHighlight = useCallback((region) => {
        dispatch(setSelectedHighlight(region));
    }, [dispatch]);

    useEffect(() => {
        if (regionRef.current) {
            regionRef.current.onanimationend = () => {
                setIsInit(false);
                selectRegionHighlight(null);
            }
        }

        return () => {
            if (regionRef.current) {
                regionRef.current.onanimationend = null;
            }
        }
    }, [selectRegionHighlight]);

    return (
        <div onClick={openRegion} >
            <Wrapper
                // onMouseEnter={() => setIsHover(true)}
                // onMouseLeave={() => setIsHover(false)}
                ref={regionRef}
                firstAnimation={Boolean(isInit)}
                selected={isInMap && selectedHighlight?.id === region.id}
                style={{
                    ...region.position,
                    opacity: region.active ? '1' : '0.5',
                }}
            >
                <img
                    width="100%"
                    height={"100%"}
                    src={`/assets/buildings/${region.id}.png`}
                    alt={region.name}
                />

            </Wrapper >

        </div>
    )
}
