import { GlobalOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Modal, Popover, message } from 'antd';

import Title from 'antd/es/typography/Title';
import { createLinksBtn, deleteLinksBtn, fetchAllLinks, saveLinksBtn } from '../../../../features/api/linksAPI';
import { setLanguage } from '../../../../features/languageSlice';
import LinksItemCard from './LinksItemCard';
import LinksItemInfoPopover from './LinksItemInfoPopover';
import { getAllRegionsPublic } from '../../../../features/regionsSlice';


const ContentWrapper = styled.div`
  width: 100%;
  position: relative;
  min-height: 600px;
  max-height: 650px;
 
`;

const CardsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 25px;
  padding:30px;
`;

const HeaderControlsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
`;


export default function LinksEditorModal({
  isVisible,
  status,
  handleConfirm,
  handleClose,
  handleAfterClose,
}) {
  const dispatch = useDispatch();

  const { items: REGIONS } = useSelector(
    (state) => state.regions
  );


  const [linksButtons, setLinksButtons] = useState([]);
  // const [selectedBio, setSelectedBio] = useState();



  const [isCreateLink, setIsCreateLink] = useState(false);
  const [isCreatingLink, setIsCreatingLink] = useState(false);


  const loadAllButtons = async () => {
    try {
      const { data } = await fetchAllLinks();
      setLinksButtons(data);
    } catch (error) {
      console.log(error);
    }
  }

  const loadRegions = useCallback(() => {
    dispatch(getAllRegionsPublic())
  }, [dispatch]);

  const createItem = async (btnData) => {
    try {
      setIsCreatingLink(true);
      const { data } = await createLinksBtn(btnData);
      const updatedBtns = [...linksButtons, { ...btnData, id: data }];
      setLinksButtons(updatedBtns);
      setIsCreatingLink(false);
      closeCreateLink();
      message.success("Link created successfully");
    } catch (error) {
      setIsCreatingLink(false);
      message.error("Link create failed " + error);
    }
  }

  const updateItem = (linkToUpdate) => {
    return new Promise(async (resolve, reject) => {
      try {
        await saveLinksBtn(linkToUpdate);
        const updatedBtns = [
          ...linksButtons.filter((btn) => btn.id !== linkToUpdate.id),
          { ...linkToUpdate }
        ];
        setLinksButtons(updatedBtns);
        message.success("Link updated successfully");
        resolve();
      } catch (error) {
        console.log(error);
        reject();
        message.error("Link update failed " + error);
      }
    });


  }

  const deleteItem = async (id) => {
    try {
      await deleteLinksBtn(id);
      const updatedBtns = linksButtons.filter((btn) => btn.id !== id);
      setLinksButtons(updatedBtns);
    } catch (error) {
      console.log(error);
    }
  }


  const openCreateLink = () => {
    setIsCreateLink(true);
  }

  const closeCreateLink = (withLoad = false) => {
    setIsCreateLink(false);
  }


  const { language } = useSelector(
    (state) => state.language
  );

  const getDirection = () => {
    return language === "he" ? "rtl" : "ltr";
  }

  const toggleLanguage = () => {
    const lang = language === "he" ? "en" : "he";
    dispatch(setLanguage(lang));
  }



  useEffect(() => {
    if (isVisible) {
      loadRegions();
      loadAllButtons();
    }
  }, [isVisible]);

  return (
    <>
      <Modal
        keyboard
        style={{
          direction: getDirection(),
        }}
        open={isVisible}
        onOk={handleConfirm}
        onCancel={handleClose}
        okText={"Save"}
        cancelText={'Cancel'}
        confirmLoading={status === 'loading'}
        okButtonProps={{ type: 'primary' }}
        width={'80%'}
        footer={[
          <Popover
            open={isCreateLink}
            content={
              <LinksItemInfoPopover
                REGIONS={REGIONS}
                linkItem={undefined}
                createItem={createItem}
                closeMe={closeCreateLink}
              />

            }
            title="Link Info"
            trigger="click"
          >
            <Button
              key="submit"
              type="primary"
              loading={isCreatingLink}
              onClick={openCreateLink}
            >
              Create
            </Button>
          </Popover>]}

      >
        <div>
          <HeaderControlsWrapper>
            <Title style={{ margin: 0 }} level={4}>{`Manage Links`}</Title>
            <Button
              onClick={toggleLanguage}
              className='create-btn'
              icon={<GlobalOutlined />}
            >
              {language === "en" ? "he" : "en"}
            </Button>

          </HeaderControlsWrapper>
          <ContentWrapper>
            <CardsWrapper>
              {linksButtons.map((link) =>
                <LinksItemCard
                  key={link.id}
                  REGIONS={REGIONS}
                  linkItem={link}
                  language={language}
                  updateItem={updateItem}
                  deleteItem={deleteItem}
                />
              )}

            </CardsWrapper>
          </ContentWrapper>

        </div>

      </Modal >

    </>
  );
}
