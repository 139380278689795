import { Layout } from 'antd';
import React, { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import FooterControl from './components/Footer/Footer';
import RequireAuth from './components/Routing/RequireAuth';
import RoutingLayout from './components/Routing/RoutingLayout';
import Login from './views/Login';

import { useDispatch } from 'react-redux';
import './App.css';
import Header from "./components/Header/Header";
import { loginValidation } from './features/authSlice';
import httpInterceptors from './services/httpInterceptors';
import MapEditor from './views/MapEditor';
import Opener from './views/Opener';

const { Content } = Layout;


function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();


  useEffect(() => {
    httpInterceptors.initInterceptors(navigate);


    if (location.pathname !== "/map") {
      dispatch(loginValidation());
    }

    return () => {
      httpInterceptors.clearInterceptors();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Layout>
      <Header />
      <Content style={{
        height: location.pathname !== "/map" ? 'calc(100vh - 145px)' : "100%",
        overflowY: 'auto'

      }}>
        <Routes>
          <Route path="/map" element={<Opener />} />
          <Route path="/" element={<RoutingLayout />} >
            <Route path="login" element={<Login />} />

            <Route element={<RequireAuth />}>
              <Route path="/" element={<MapEditor />} />
            </Route>
          </Route>

        </Routes>
      </Content>
      <FooterControl />
    </Layout>
  );
}

export default App;
