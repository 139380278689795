import React from 'react';

import styled from '@emotion/styled';
import TextArea from 'antd/es/input/TextArea';


import { css } from '@emotion/react';
import { useDispatch, useSelector } from 'react-redux';
import { setLanguage } from '../../../features/languageSlice';
import TextInfoBG from '../../../images/info_box.png';
import { isMobile } from '../../../utils/general';
import BackBtn from '../../styledComponents/BackBtn';
import LanguageBtn from '../../styledComponents/LanguageBtn';


const BlackBase = styled.div`
    background-color: rgba(0,0,0,0.7);
    width: 100vw;
    height: 100%;
    cursor: pointer;
    position: absolute;
    top: 0;
`;

const Wrapper = styled.div`
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    opacity: 0;
    pointer-events: none;
    transition: opacity .3s ease-in-out;
    

    ${props => props.open && css`
        pointer-events: all;
        opacity: 1;
    `}

    
`;

const CenterContent = styled.div`
	/* width: 1280px;
	height: 720px;  */
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
`;

const MainContentSectionWrapper = styled.div`
    width: 1280px;
    height: 100%;
    transform: scale(.65);//${props => !props.isMobile ? "scale(0.75)" : "scale(0.65)"};
`;


const BoxBaseImg = styled.img`
    position: absolute;
    left:50%;
    top: 50%;
    transform: translate(-52%, -50%);
`

const TextAreaCustom = styled(TextArea)`
    white-space: pre-wrap;
    resize: none !important;
    border: none;
    user-select: none;
    font-family: IBMPlexSansHebrew-Medium;
    background: transparent;
    font-size: 1.7rem;
    width: 1950px;
    height: 800px !important;
    position: absolute;
    top: 50%;
    transform: translate(${props => props.lang === "he" ? "50%" : "-50%"}, -48%);

    ${props => props.lang === 'he' ? css`
        right: 50%;
    ` : css`
        left: 50%;
    `}
`


const HeaderControlsWrapper = styled.div`
    width: 1280px;//${props => props.isMobile ? "100%" : "85%"};
    /* height: 60px; */
    display: flex;
    align-items: center;
    justify-content: flex - start;
    position: relative;

    margin: 0 auto;
    z-index: 9999;
`;


let hebValue = `גטו טרזיינשטאט הוקם בעיר המבצר טרזין (בצ'כית), הממוקמת כ-60 ק"מ צפונית לפראג.\n
ב-24 בנובמבר 1941 הוקם הגטו, שנועד כביכול להוות מעין" עיר מקלט" עבור יהודי צ'כיה עד יעבור זעם ותסתיים המלחמה. למעשה, היה זה מחנה מעבר למחנות המוות ואתרי הרצח במזרח.\n
החל ממחצית 1942 נשלחו לטרזיינשטאט גם יהודים מארצות מערב אירופה: אוסטריה, גרמניה, הולנד ובשנת 1943 גם מדנמרק. בתקופת השיא נדחסו בין חומות העיר, שיועדה להכיל כ-7000 איש, כ-58,000 אסירים. \n
חרף הקשיים והמצוקה התאפיין גטו טרזיינשטאט בפעילות ארגונית מקיפה של ההנהגה היהודית, שבאה לביטוי בכל תחומי החיים - טיפול וחינוך ילדים ונוער, שירותי בריאות, חלוקת מזון, עבודה ועוד. כל אלה שיפרו אמנם את תנאי החיים הקשים, אך לא יכלו למנוע את הצפיפות, המחלות, הרעב, והתמותה הגבוהה.\n
כ-160,000 יהודים עברו בגטו טרזיינשטאט, חלקם הגיעו במסגרת פינוי מחנות ריכוז אחרים וצעדות מוות. כ-88,000 מהם נשלחו למחנות המוות ואתרי הרצח במזרח וכ-35,000 מתו בגטו עצמו ממחלות ורעב. \n
גטו טרזיינשטאט שוחרר על ידי הצבא האדום ב-8 במאי 1945.
`

let engValue = `The Theresienstadt ghetto was established in the fortress city of Terezín (in Czech), located about 60 km north of Prague.
On November 24, 1941, the Ghetto was established, supposedly intended to be a kind of "refuge city" for Czech Jews until the rampage passes and the war ends. In reality, it was a transit camp to the death camps and murder sites in the East.\n
From mid-1942, Jews from Western European countries were also sent to Terezín: from
Austria, Germany, Holland and, in 1943, Denmark. At the peak period, 58,000 prisoners
were held in Terezín, which was designed for about 7,000 people.
Despite the difficulties and hardships, Ghetto Theresienstadt was characterized by the all-
round organizational activity of the Jewish leadership, which was manifested in all areas of
life - care and education of children and youth, health services, food distribution, work, and
more. 
All this improved the difficult living conditions, but failed to prevent overcrowding,
disease, hunger and high mortality.\n
Some 160,000 Jews passed through Ghetto Theresienstadt, some of them as part of
evacuations from other concentration camps and death marches. About 88,000 of them
were sent to extermination camps and killing sites in the East, and about 35,000 died of
disease and starvation in the ghetto itself.\n
The Theresienstadt ghetto was liberated by the Red Army on May 8, 1945.
`

export default function MainInfoPage({ open, handleClose }) {
    const dispatch = useDispatch();

    const { language } = useSelector(
        (state) => state.language
    );

    const toggleLanguage = () => {
        const lang = language === "he" ? "en" : "he";
        dispatch(setLanguage(lang));
    };

    const isHebrew = () => language === "he";
    const isEnglish = () => language === "en";


    return (
        <Wrapper open={open}>
            <BlackBase onClick={handleClose} />
            <HeaderControlsWrapper isMobile={isMobile()}>
                <LanguageBtn
                    style={{
                        top: "30px",
                        right: "50px",
                    }}
                    onClick={toggleLanguage}
                />
                <BackBtn
                    style={{
                        top: "30px",
                        left: "50px",
                    }}
                    onClick={handleClose}
                />
            </HeaderControlsWrapper>
            <CenterContent>
                <MainContentSectionWrapper isMobile={isMobile()}>
                    <>
                        <BoxBaseImg src={TextInfoBG} alt="text base" />

                        {isHebrew() &&
                            <TextAreaCustom
                                lang={language}
                                dir='rtl'
                                value={hebValue}
                                rows={20}
                                cols={70}
                                placeholder="לא נמצא ערך"
                            />
                        }
                        {isEnglish() &&
                            <TextAreaCustom
                                lang={language}
                                value={engValue}
                                rows={20}
                                cols={70}
                                placeholder="No info found"
                            />
                        }
                    </>
                </MainContentSectionWrapper>
            </CenterContent>
        </Wrapper>
    )
}
