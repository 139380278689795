import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { login, setLoggedInStatus } from "../features/authSlice";
import { message } from "antd";

const useAuth = () => {
	const dispatch = useDispatch();


	const navigate = useNavigate();
	const location = useLocation();
	const { user, loginStatus, isLoggedIn } = useSelector((state) => state.auth);

	let locationState = location.state;

	const from = locationState?.from?.pathname || "/";

	/* A hook that is used to login a user. */
	const userLogin = useCallback(
		(name, password, remember = false) => {
			dispatch(login({ name, password, remember }));
		},
		[dispatch]
	);
	/* A hook that is used to set the login status. */
	const setStatus = useCallback(
		(status) => {
			dispatch(setLoggedInStatus(status));
		},
		[dispatch]
	);

	useEffect(() => {
		if (loginStatus === "success") {
			setTimeout(() => {
				navigate(from, { replace: true });
			}, 10);
			setStatus("idle");
		}
		if (loginStatus === "failed") {
			message.error("User or Password incorrect, try again !");
			setStatus("idle");
		}
	}, [from, navigate, setStatus, user, loginStatus]);

	return {
		login: userLogin,
		isLoggedIn,
		loginStatus
	};
};

export default useAuth;
