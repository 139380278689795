import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Modal } from 'antd';
import Title from 'antd/es/typography/Title';
import React, { useEffect, useRef, useState } from 'react';
import { COLORS } from '../../../config/colors';
import { isMobile } from '../../../utils/general';
import ArrowBtn from '../../styledComponents/ArrowBtn';
import { PlayCircleOutlined } from '@ant-design/icons';

const Wrapper = styled.div`
  width: 100%;
  z-index: 999;
  position: relative;
  /* margin: 0 auto; */
  text-align: center;
`;

const ArrowsWrapper = styled.div`
    position: fixed;
    width: 90%;
    height: 10px;
    left: 50%;
    bottom: 75px;
    transform: translate(-50%, 0);
    opacity: 0;

    transition: opacity .2s ease-out;

    ${props => props.isOpen && css`
        opacity: 1;
    `}
`;

const PlayBtn = styled.button`
  border: none;
  margin: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: transparent;
`;

export default function PreviewModal({
  modalKey,
  isOpen,
  handleCancel,
  file,
  prevItem,
  nextItem,
  pageNum,
  totalPages,
  isPortrait,
  isSquare
}) {
  const [showArrows, setShowArrows] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  const videoPlayerRef = useRef();

  const handleOpenChange = (value) => {
    setShowArrows(value);
  }

  const getImageWidth = () => {
    if (isSquare) {
      return "auto";
    }
    return isPortrait ? "auto" : "100%";
  }

  const getImageHeight = () => {
    if (isSquare) {
      return "auto";
    }
    return isPortrait ? "100%" : "auto";
  }

  const playVideo = () => {
    if (videoPlayerRef.current) {
      videoPlayerRef.current.play();
    }
  };

  // useEffect(() => {
  //   if (videoPlayerRef.current) {
  //     videoPlayerRef.current.onplay = () => setIsPlaying(true);
  //     videoPlayerRef.current.onpause = () => setIsPlaying(false);
  //   }

  //   return () => {
  //     if (videoPlayerRef.current) {
  //       videoPlayerRef.current.onplay = null;
  //       videoPlayerRef.current.onpause = null;
  //     }
  //   }

  // }, [videoPlayerRef]);

  return (
    <Modal
      open={isOpen}
      onCancel={handleCancel}
      afterOpenChange={handleOpenChange}
      width={isPortrait ? "50%" : "70%"}
      title={totalPages ? `${pageNum}/${totalPages}` : "_"}
      keyboard
      footer={null}
      centered
      wrapClassName={totalPages ? '' : 'title'}
    >
      <Wrapper >

        {isOpen && <>
          {file?.type === 'image' && (
            <img
              id="prev-image"
              alt={file.fileName}
              width={getImageWidth()}
              height={getImageHeight()}
              style={{ transform: "scale(1)", maxHeight: "800px" }}
              src={file?.url || file?.preview}

            />
          )}
          {file?.type === 'video' && <>
            <video
              ref={videoPlayerRef}
              onPlay={() => setIsPlaying(true)}
              onPause={() => setIsPlaying(false)}
              src={`/uploads/${file?.fileName}`}
              style={{ width: '100%' }}
              controls
              autoPlay
              muted
            />
            {!isPlaying && <PlayBtn onClick={playVideo}>
              <PlayCircleOutlined style={{ color: "#fff", fontSize: "10rem" }} />
            </PlayBtn>
            }
          </>
          }
          {file?.type === "application" && (
            <div id="pdfRenderer"></div>
          )}
        </>}

        {totalPages > 1 && prevItem && nextItem &&
          <ArrowsWrapper isMobile={isMobile()} isOpen={showArrows}>
            <ArrowBtn
              handle={"previous"}
              style={{
                bottom: "0",
                left: "50px",
                transform: "translate(0, 50%)"
              }}
              onClick={prevItem}
            />
            <ArrowBtn
              handle={"next"}
              style={{
                bottom: "0",
                right: "50px",
                transform: "translate(0, 50%) scale(-1)"
              }}
              onClick={nextItem}
            />
          </ArrowsWrapper>}
      </Wrapper>
    </Modal>
  );
}
